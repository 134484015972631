<template>
    <div>
        <div class="search">
            <el-form ref="form" :model="searchForm" label-width="100px" style="height: 34px">
                <el-row :gutter="24">
                    <el-col :span="4">
                        <el-form-item label="供应商编号：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col>
                        <el-col :span="4">
                        <el-form-item label="供应商账号：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="供应商名称：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="提交时间：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="审核状态：">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" style="text-align: center">
                        <el-button type="primary" icon="el-icon-search">查询</el-button>
                        <a class="clear">清空</a>
                    </el-col>

                </el-row>
            </el-form>
        </div>

        <div class="content">
            <h4>供应商审核列表</h4>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部" name=""></el-tab-pane>
                <el-tab-pane label="已通过" name="1"></el-tab-pane>
                <el-tab-pane label="已拒绝" name="2"></el-tab-pane>
            </el-tabs>

            <div style="padding: 0 24px">
                <el-table border :data="tableData">
                    <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column>
                            <el-table-column prop="name" label="供应商编号"></el-table-column>
                            <el-table-column prop="name" label="供应商账号"></el-table-column>
                            <el-table-column prop="name" label="联系人"></el-table-column>
                            <el-table-column prop="name" label="供应商名称"></el-table-column>
                            <el-table-column prop="name" label="店铺名称"></el-table-column>
                            <el-table-column prop="name" label="提交时间"></el-table-column>
                            <el-table-column prop="name" label="审核状态"></el-table-column>
                            <el-table-column prop="name" label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                                    <el-button type="text" size="small">编辑</el-button>
                                </template>
                            </el-table-column>
                </el-table>
            </div>

            <div style="margin-right: 24px; margin-top: 12px; padding-bottom: 20px">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage4"
                            :page-sizes="[100, 200, 300, 400]"
                            :page-size="100"
                            style="float: right"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="400">
                        </el-pagination>
                    </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            activeName: '',
            tableData: []
        }
    }
}
</script>

<style scoped>
  .search {
      background: #fff;
      padding: 24px;
  }
  .search >>> .el-form .el-input__inner {
      height: 34px;
  }
  .search >>> .el-button {
      padding: 9px 20px;
  }
  .search >>> .el-col {
      height: 34px;
  }
  .search >>> .el-form-item__label {
      line-height: 34px;
  }
  .search >>> .el-form-item__content {
      line-height: 34px;
  }
  .content >>> .el-tabs__nav-wrap {
      padding: 0 28px;
  }
  .content >>> .el-tabs .is-active {
      background: rgba(25, 145, 252, 0.1);
  }
  .content >>> .el-tabs .el-tabs__item {
      /* padding: 0; */
      

  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 20px;
  }
  .content >>> .el-tabs--top .el-tabs__item.is-top:last-child {
      padding-right: 20px;
  }
  .clear {
      font-size: 14px;
      color: #8C9099;
      margin-left: 24px;
  }
  .content {
      /* padding: 24px; */
      padding: 24px 0;
      background: #fff;
      margin-top: 14px;
  }
  .content >h4 {
      padding: 0 24px;
      margin-bottom: 20px;
  }
</style>